import React from "react"
import { Link } from "gatsby"
import { Seo } from "../components/Seo/Seo"

export default function SecurityPage() {
  return (
    <React.Fragment>
      <style>
        {`
        .imagePad {
          padding-top: 15px;
          padding-left: 15px;
        }
        `}
      </style>
      <Seo
        title="SAS70 Certified Data Center, Secure Mail Service Provider"
        description="BIGEYE Direct is a SAS70 certified data center in the Mid-Atlantic region. Full-service direct mail production."
      />
      <div id="wrapper">
        <div id="topper">
          <div id="logo">
            <Link to="/">
              <img
                src="/wordmark-dark.svg"
                width="234"
                height="108"
                alt="Big Eye Direct"
              />
            </Link>
          </div>
          <div id="contact">
            <h2>703-955-3017</h2>
            <p>
              <a href="mailto:damon@bigeyedirect.com">info@bigeyedirect.com</a>
            </p>
          </div>
        </div>
        <div id="content">
          <div id="nav">
            <ul>
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/services/">SERVICES</Link>
              </li>
              <li>
                <Link to="/facility/">OUR FACILITY</Link>
              </li>
              <li className="active">
                <Link to="/security/">SECURITY</Link>
              </li>
              <li>
                <Link to="/about/">ABOUT US</Link>
              </li>

              <li className="endNav">
                <Link to="/contact/">CONTACT US</Link>
              </li>
            </ul>
          </div>
          <div className="clear"></div>
          <div id="flasharea">
            {" "}
            <img src="/images/photo_security.jpg" width="926" height="216" />
          </div>
          <div id="textarea">
            <h3>
              <span className="txt_color">SAS70 Certification</span>
            </h3>
            <p>
              Your data equals your life blood as it contains critical
              information about your donors, account holders, and
              customers.&nbsp; Should it get into the wrong hands, it can have
              catastrophic consequences for your organization.&nbsp; To
              alleviate this fear, Bigeye Direct is one of the few mailing
              companies in the Mid-Atlantic region to operate in a SAS70
              certified data center.&nbsp; SAS70 certification is the most
              secure rating a data center can achieve so you can rest assured
              that your data is secure while under our watch.&nbsp; We support
              PGP encryption for transferring files across our secure ftp and
              our servers reside offsite in the SAS70 facility so you never have
              to worry about your data walking away.&nbsp; Our commitment to
              data security has allowed us to pass numerous security audits from
              major financial institutions as well as state and local
              governments and we consider it a defining factor separating us
              from other mailers.
            </p>
            <h3>
              <span className="txt_color">
                <img
                  src="/images/iStock_000001617689XSmall.jpg"
                  alt=""
                  width="277"
                  height="184"
                  // align="right"
                  className="imagePad"
                />
                24 Hour Video Surveillance{" "}
              </span>
            </h3>
            <p>
              Physical plant security is equally as important as data
              security.&nbsp; Our state of the art 32,000 SF facility is under
              interior &amp; exterior 24 hour video surveillance and utilizes
              the finest camera equipment available on the market.&nbsp; Our
              building is passkey protected throughout and critical areas are
              only accessible to employees who have been granted access.
            </p>

            <div className="clear"></div>
          </div>
        </div>

        <div id="footer">
          <a
            rel="noopener noreferrer"
            href="http://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=13860+Redskin+Drive+Herndon,+VA+2017&amp;sll=37.0625,-95.677068&amp;sspn=52.637906,88.330078&amp;ie=UTF8&amp;hq=&amp;hnear=13860+Redskin+Dr,+Herndon,+Fairfax,+Virginia+20171&amp;z=17"
            target="_blank"
          >
            13860 Redskin Drive • Herndon, VA 20171
          </a>
        </div>
        <div id="copyright">
          ©2011 BIGEYE Direct. All rights reserved. Sitemap
        </div>
      </div>
    </React.Fragment>
  )
}
